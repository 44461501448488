<template>
  <b-row>
    <b-col
      lg="7"
      md="12"
      sm="12"
    >
      <b-card :title="formData.title">
        <validation-observer
          ref="editCommunicationEmailTemplate"
          #default="{invalid}"
        >
          <!-- form -->
          <b-form
            v-if="formData"
            class="mt-2"
            sm="12"
          >
            <b-row>
              <b-col
                sm="12"
                class="mb-1"
              >
                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="4"
                  content-cols-sm
                  content-cols-lg="8"
                  label="Active"
                  label-for="is-active"
                >
                  <b-form-checkbox
                    v-model="formData.is_active"
                    name="is-active"
                    switch
                    inline
                  />
                </b-form-group>
              </b-col>

              <b-col
                sm="12"
                class="mb-1"
              >
                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="4"
                  content-cols-sm
                  content-cols-lg="8"
                  label="Send"
                >
                  <time-offset
                    ref="TimeOffset"
                    :time-in-seconds="formData.settings.time_offset"
                  />
                  <div class="d-inline-block">
                    {{ communicationTemplate.send_time_label }}
                  </div>
                </b-form-group>
              </b-col>

              <b-col
                sm="12"
                class="mb-1"
              >
                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="4"
                  content-cols-sm
                  content-cols-lg="8"
                  label="BCC Email"
                >
                  <validation-provider
                    #default="{ errors }"
                    ref="bcc_email"
                    name="BCC email"
                    vid="bcc_email"
                  >
                    <b-form-input
                      v-model.trim="formData.bcc_email"
                      placeholder=""
                      name="bcc_email"
                      type="email"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col
                sm="12"
                class="mb-1"
              >
                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="4"
                  content-cols-sm
                  content-cols-lg="8"
                  label="Message"
                >
                  <validation-provider
                    #default="{ errors }"
                    ref="body"
                    name="Message Content"
                    vid="body"
                    rules="required"
                  >
                    <b-form-textarea
                      v-model.trim="formData.body"
                      class="form-message"
                      name="body"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-card-footer class="no-padding">
              <b-col
                cols="12"
                class="d-flex justify-content-between"
                style="padding-left:0;padding-right:0;"
              >
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-secondary"
                  type="reset"
                  class="mt-2"
                  @click.prevent="resetForm"
                >
                  Reset
                </b-button>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mt-2"
                  :disabled="invalid || isSubmitting"
                  @click="submitForm"
                >
                  Save changes
                </b-button>

              </b-col>
            </b-card-footer>
          </b-form>
        </validation-observer>
      </b-card>
    </b-col>
    <b-col
      lg="5"
      md="12"
      sm="12"
    >
      <div class="h4 pt-2 mb-2">
        Merge Tags
      </div>
      <div class="mb-2">
        Insert personalized information in your messages.
      </div>
      <MergeTagsList />
    </b-col>
  </b-row>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import {
  BButton,
  BCard,
  BCol,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BRow,
  BCardFooter,
} from 'bootstrap-vue'
import MergeTagsList from '@/views/components/merge-tags-list/MergeTagsList.vue'
import TimeOffset from '@/views/components/time/TimeOffset.vue'
import Ripple from 'vue-ripple-directive'
import AccountService from '@/services/AccountService'
import { cloneDeep } from 'lodash'
import { handleValidationError } from '@/utils/helpers'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BFormTextarea,
    BFormCheckbox,
    TimeOffset,
    BCardFooter,
    ValidationProvider,
    ValidationObserver,
    MergeTagsList,
  },
  directives: {
    Ripple,
  },
  props: {
    initialFormData: Object,
    communicationTemplate: Object,
    onFormSaved: Function,
  },
  data() {
    return {
      isSubmitting: false,
      formData: null,
      required,
    }
  },
  created() {
    this.resetForm()
  },
  methods: {
    async submitForm() {
      this.$refs.editCommunicationEmailTemplate.validate().then(async success => {
        if (success) {
          this.isSubmitting = true
          const data = {
            is_active: this.formData.is_active,
            bcc_email: this.formData.bcc_email,
            subject: this.formData.subject,
            body: this.formData.body,
            settings: {
              time_offset: this.$refs.TimeOffset.getTimeInSeconds(),
            },
          }
          try {
            AccountService.updateCommunicationTemplate(this.communicationTemplate.id, data)
              .then(() => {
                this.onFormSaved()
                setTimeout(() => {
                  this.isSubmitting = false
                }, 2000)
              })
              .catch(error => {
                handleValidationError(error, this)
                this.isSubmitting = false
              })
          } catch (error) {
            console.error(error)
          }
        }
      })
    },

    resetForm() {
      this.formData = cloneDeep(this.initialFormData)
    },
  },
}
</script>

<style scoped>
.custom-control {
  min-height: 1.7rem
}
.no-padding{
  padding:0
}
.form-message {
  min-height: 170px
}
</style>
