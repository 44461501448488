<template>
  <div v-if="communicationTemplate.channel == 'email'">
    <EditCommunicationTemplateEmailForm
      :communication-template="communicationTemplate"
      :initial-form-data="communicationTemplate"
      :on-form-saved="onFormSaved"
    />
  </div>
  <div v-else>
    <EditCommunicationTemplateTextForm
      :communication-template="communicationTemplate"
      :initial-form-data="communicationTemplate"
      :on-form-saved="onFormSaved"
    />
  </div>
</template>

<script>
import store from '@/store'
import EditCommunicationTemplateEmailForm from '@/views/pages/communication/EditCommunicationTemplateEmailForm'
import EditCommunicationTemplateTextForm from '@/views/pages/communication/EditCommunicationTemplateTextForm'

export default {
  components: {
    EditCommunicationTemplateEmailForm,
    EditCommunicationTemplateTextForm,
  },
  props: ['templateId', 'prevPath'],
  data() {
    return {
      communicationTemplate: store.getters['auth/communicationTemplateById'](this.templateId),
    }
  },
  methods: {
    async onFormSaved() {
      await store.dispatch('auth/getAuthUser')
      if (this.prevPath) {
        this.$router.push(this.prevPath)
      } else {
        this.$router.push({ name: 'list-communication-templates' })
      }
    },
  },
}
</script>
