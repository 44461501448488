<template>
  <b-row>
    <b-col
      lg="5"
      md="12"
    >
      <b-card :title="formData.title">
        <validation-observer
          ref="editCommunicationTextTemplate"
          #default="{invalid}"
        >
          <!-- form -->
          <b-form
            v-if="formData"
            class="mt-2"
            sm="6"
          >
            <b-row>
              <b-col
                sm="12"
                class="mb-1"
              >
                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="4"
                  content-cols-sm
                  content-cols-lg="8"
                  label-for="is-active"
                  label="Active"
                >
                  <b-form-checkbox
                    v-model="formData.is_active"
                    name="is-active"
                    switch
                    inline
                  />
                </b-form-group>
              </b-col>
              <b-col
                sm="12"
                class="mb-1"
              >
                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="4"
                  content-cols-sm
                  content-cols-lg="8"
                  label="Send"
                  label-for="time_offset"
                >
                  <time-offset
                    ref="TimeOffset"
                    :time-in-seconds="formData.settings.time_offset"
                  />
                  <div class="d-inline-block">
                    {{ communicationTemplate.send_time_label }}
                  </div>
                </b-form-group>
              </b-col>
              <b-col
                sm="12"
                class="mb-1"
              >
                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="4"
                  content-cols-sm
                  content-cols-lg="8"
                  label="BCC Phone"
                >
                  <b-form-input
                    v-model="formData.bcc_phone"
                    placeholder=""
                    name="bcc"
                    type="tel"
                  />
                </b-form-group>
              </b-col>

              <b-col
                sm="12"
                class="mb-1"
              >
                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="4"
                  content-cols-sm
                  content-cols-lg="8"
                  label="Message"
                >
                  <validation-provider
                    #default="{ errors }"
                    ref="body"
                    name="Message Content"
                    vid="body"
                    rules="required"
                  >
                    <b-form-textarea
                      v-model="formData.body"
                      class="form-message"
                      name="body"
                      :state="errors.length > 0 ? false:null"
                      maxlength="400"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

            </b-row>
            <b-card-footer class="no-padding mt-2">
              <b-row>
                <b-col
                  cols="12"
                  class="d-flex justify-content-between"
                >
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-secondary"
                    type="reset"
                    class="mt-2"
                    @click.prevent="resetForm"
                  >
                    Reset
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="mt-2"
                    :disabled="invalid || isSubmitting"
                    @click="submitForm"
                  >
                    Save changes
                  </b-button>

                </b-col>
              </b-row>
            </b-card-footer>
          </b-form>
        </validation-observer>
      </b-card>
    </b-col>
    <b-col
      lg="3"
      md="6"
    >
      <div class="h4 pt-2 mb-2">
        Merge Tags
      </div>
      <div class="mb-2">
        Insert personalized information in your messages.
      </div>
      <MergeTagsList />
    </b-col>
    <b-col
      lg="4"
      md="6"
    >
      <div
        class="custom-mockup"
        :style="{ backgroundImage: `url(${require('@/assets/images/phone_mockup.svg')})` }"
      >
        <div class="custom-container">
          <div class="custom-overflow">
            <div class="custom-message">
              {{ formData.body|renderSampleTemplate }}
            </div>
          </div>
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import {
  BButton,
  BCard,
  BCol,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BRow,
  BCardFooter,
} from 'bootstrap-vue'
import MergeTagsList from '@/views/components/merge-tags-list/MergeTagsList.vue'
import TimeOffset from '@/views/components/time/TimeOffset.vue'
import Ripple from 'vue-ripple-directive'
import AccountService from '@/services/AccountService'
import { cloneDeep } from 'lodash'
import { handleValidationError } from '@/utils/helpers'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BFormTextarea,
    BFormCheckbox,
    TimeOffset,
    BCardFooter,
    ValidationProvider,
    ValidationObserver,
    MergeTagsList,
  },
  directives: {
    Ripple,
  },
  props: {
    initialFormData: Object,
    communicationTemplate: Object,
    onFormSaved: Function,
  },
  data() {
    return {
      isSubmitting: false,
      formData: null,
      required,
    }
  },
  created() {
    this.resetForm()
  },
  methods: {
    async submitForm() {
      this.$refs.editCommunicationTextTemplate.validate().then(async success => {
        if (success) {
          this.isSubmitting = true
          const data = {
            is_active: this.formData.is_active,
            bcc_phone: this.formData.bcc_phone,
            body: this.formData.body,
            settings: {
              time_offset: this.$refs.TimeOffset.getTimeInSeconds(),
            },
          }
          try {
            AccountService.updateCommunicationTemplate(this.communicationTemplate.id, data)
              .then(() => {
                this.onFormSaved()
                setTimeout(() => {
                  this.isSubmitting = false
                }, 2000)
              })
              .catch(error => {
                handleValidationError(error, this)
                console.log(error)
                this.isSubmitting = false
              })
          } catch (error) {
            console.error(error)
          }
        }
      })
    },

    resetForm() {
      this.formData = cloneDeep(this.initialFormData)
    },
  },
}
</script>

<style scoped>
  .time-offset-group {
    max-width: 170px;
  }

  .custom-control {
    min-height: 1.7rem
  }

  .custom-mockup {
    background-size: 100%;
    background-position: top;
    display: flex;
    height: 400px;
  }

  .custom-container {
    margin: 23.6% 0 0;
    padding: 6% 12%;
    width: 100%;
  }

  .custom-overflow {
    overflow: hidden;
    padding-left: 7px;
  }

  .custom-message {
    background: #00e34d;
    color: white;
    float: left;
    clear: both;
    border-bottom-right-radius: 20px 0px \9;
    position: relative;
    padding: 10px 20px;
    border-radius: 25px;
    font: 400 15px 'Open Sans', sans-serif;
    word-wrap: break-word;
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 1.4;
    max-width: 90%;
  }

  .custom-message:before {
    content: "";
    position: absolute;
    z-index: 2;
    bottom: -2px;
    left: -7px;
    height: 19px;
    border-left: 20px solid #00e34d;
    border-bottom-right-radius: 16px 14px;
    -webkit-transform: translate(0, -2px);
    transform: translate(0, -2px);
    border-bottom-right-radius: 15px 0px \9;
    transform: translate(-1px, -2px) \9;
  }

  .custom-message:after {
    content: "";
    position: absolute;
    z-index: 3;
    bottom: -2px;
    left: 4px;
    width: 26px;
    height: 20px;
    background: white;
    border-bottom-right-radius: 10px;
    -webkit-transform: translate(-30px, -2px);
    transform: translate(-30px, -2px);
  }
  .no-padding{
    padding:0;
  }
  .form-message {
    min-height: 130px
  }
</style>
