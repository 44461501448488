<template>
  <b-input-group
    class="time-offset-group d-inline-flex ml-0 mb-1 mr-1"
    style="    padding-left: 3px;"
  >
    <b-form-input
      v-model="timeInUnits"
      placeholder=""
      name="time_offset"
      type="number"
      min="0"
    />
    <b-form-select
      v-model="selectedTimeUnit"
      :options="timeUnits"
      name="send_time_unit"
      class="btn dropdown-toggle btn-primary time-offset-select"
    />
  </b-input-group>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
} from 'bootstrap-vue'

export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BFormSelect,
  },
  props: {
    timeInSeconds: [String, Number],
    sendTimeLabel: String,
  },
  data() {
    return {
      timeInUnits: Number,
      selectedTimeUnit: 'hours',
      timeUnits: [
        {
          value: 'minutes',
          text: 'minutes',
        },
        {
          value: 'hours',
          text: 'hours',
        },
        {
          value: 'days',
          text: 'days',
        },
      ],
    }
  },
  watch: {
    timeInUnits(value) {
      if (value < 0) {
        this.timeInUnits = 0
      }
    },
  },
  created() {
    this.convertTimeForInput()
  },
  methods: {
    getTimeInSeconds() {
      if (this.selectedTimeUnit == 'days') {
        return this.timeInUnits * (60 * 60 * 24)
      } if (this.selectedTimeUnit == 'hours') {
        return this.timeInUnits * (60 * 60)
      }
      return this.timeInUnits * 60
    },
    convertTimeForInput() {
      if (this.timeInSeconds >= 60 * 60 * 24) {
        this.selectedTimeUnit = 'days'
        this.timeInUnits = Math.floor(this.timeInSeconds / (60 * 60 * 24))
      } else if (this.timeInSeconds >= 60 * 60) {
        this.selectedTimeUnit = 'hours'
        this.timeInUnits = Math.floor(this.timeInSeconds / (60 * 60))
      } else {
        this.selectedTimeUnit = 'minutes'
        this.timeInUnits = Math.floor(this.timeInSeconds / 60)
      }
    },
  },
}
</script>

<style scoped>
  .input-group.time-offset-group {
    max-width: 200px;
  }
</style>
